import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "components/Button";
import CenterFlexBox from "components/CenterFlexBox";
import Header from "components/Header";
import { copyToClipboard } from "utils";

export default function UnsupportedBrowser() {
    const handleClick = () => {
        copyToClipboard(window.location.origin + "/choose-test");
    }

    return (
        <>
            <Header />
            <CenterFlexBox>
                <CenterFlexBox
                    height='calc(100vh - 80px)'
                    backgroundColor='#235598'
                    flexDirection='column'
                    width={'100%'}
                >
                    <img
                        src="https://assets.languify.in/images/page-not-found.svg"
                        alt="Not-found"
                    />
                    <Box maxWidth={500} pl={4}>
                        <Typography
                            fontSize={26}
                            fontWeight={500}
                            color='white.main'
                            alignSelf={'flex-start'}
                            mb={1}
                        >
                            Uh-oh !
                        </Typography>
                        <Typography
                            fontSize={15} fontWeight={500} color='white.main'
                        >
                            You’re trying to access free mock test on an unsupported device,
                            to give a real TOEFL experience we only offer mock test on the web platform.
                            <br /><br />
                            For the best experience, access this site with an updated Chrome or Microsoft Edge Browser on your laptop.
                        </Typography>
                        <br />
                        <Button
                            variant="text"
                            disableElevation
                            color='white'
                            sx={{ borderBottom: '1px solid #ffffff' }}
                            style={{ cursor: 'pointer', padding: 8 }}
                            onClick={handleClick}
                        >
                            Copy test link
                        </Button>
                    </Box>
                </CenterFlexBox>
            </CenterFlexBox>
        </>
    );
}