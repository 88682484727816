import React from "react";
import { styled } from '@mui/styles';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: 10,
        maxWidth: 200,
        fontSize: '0.9rem'
    },
}))

function InfoIconWithTooltip({ title, placement = 'bottom', ...props }) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => setOpen(false);

    const handleTooltipOpen = () => setOpen(true);

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <BootstrapTooltip
                    open={open}
                    title={title} placement={placement} {...props}
                    disableFocusListener
                    disableTouchListener
                >
                    <InfoOutlinedIcon
                        onMouseOver={handleTooltipOpen}
                        onMouseLeave={handleTooltipClose}
                        onClick={handleTooltipOpen}
                        fontSize="small"
                        sx={{
                            color: '#858C90',
                            cursor: 'pointer'
                        }}
                    />
                </BootstrapTooltip>
            </div>
        </ClickAwayListener>
    );
}

export default InfoIconWithTooltip;