import { LoadingProvider, SnackbarProvider } from "contexts";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import AboutUs from "./AboutUs";
import Home from "./Home";
import Error from "./Error";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import NotFound from "./NotFound";
import PrivateRoute from "components/PrivateRoute";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import ScrollToTop from "components/ScrollToTop";
import SessionHelper from "components/SessionHelper";
import UnsupportedBrowser from "./UnsupportedBrowser";
import Workspace from "./Workspace";
import theme from "../theme";

export default function Screens() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <LoadingProvider>
            <ScrollToTop>
              <SessionHelper>
                <Routes>
                  <Route path="/error" element={<Error />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/unsupported" element={<UnsupportedBrowser />} />
                  <Route path="/workspace" element={<Workspace />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route
                    path="/home/*" element={<PrivateRoute component={Home} />}
                  />
                  <Route path="/" element={<Register />} />
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </SessionHelper>
            </ScrollToTop>
          </LoadingProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
