import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';

export default function MediaKit() {

    return (
        <Box>
            <CenterFlexBox
                fontFamily='inter' fontSize={20}
                fontWeight={500} color='white.main'
                justifyContent='flex-start'
            >
                <PermMediaOutlinedIcon /> &nbsp;
                Brand Guidelines & Media kit
            </CenterFlexBox>
            <br />
            <Typography
                fontFamily='inter' fontSize={16}
                fontWeight={400} color='white.main'>
                When you’re an affiliate and/or are using Languify’s brand assets,
                you agree to adhere to all Languify trademarks and copyrights.
                If you are found to be in violation of any of these, you will lose
                your privilege of using our brand assets. Please use our
                brand guidelines, here:
            </Typography> <br />
            <Typography
                fontFamily='inter' fontSize={16}
                fontWeight={400} color='white.main'
            >
                You can find all the &nbsp;
                <a
                    href="https://drive.google.com/drive/folders/1E4zTRzg-klB9NdOr8f3l2wxKUi8mKeTm?usp=sharing"
                    style={{ color: '#2DABFF' }}
                >
                    Brand assert in this google drive
                </a>
            </Typography>
        </Box>
    );
}