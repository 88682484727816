import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Divider } from "@mui/material";

const styles = {
    tableStyle: { border: "1px solid white", borderCollapse: "collapse", color: "white", padding: 8 },
    linkStyle: { marginLeft: 8, textDecoration: 'none', color: "#2dabff" },
};

const CAPFellowshipLink = () => (
    <a
        href={'https://drive.google.com/drive/u/0/folders/1E4zTRzg-klB9NdOr8f3l2wxKUi8mKeTm'}
        style={styles.linkStyle}
        target="_blank" rel="noreferrer"
    >
        Link here
    </a>
);


const streams = [
    [
        "Whatsapp, Instagram & Telegram",
        <>
            Whatsapp groups(College, Family & Friend groups)
            <CAPFellowshipLink />
        </>
    ],
    [
        "Facebook",
        <>
            TOEFL Preparation groups and GRE preparation groups(This can include IELTS preparation groups too).
            <CAPFellowshipLink />
        </>
    ],
    [
        "Youtube",
        <>
            A dedicated list of youtube channels will be given to you where you will have to paste the links in the comment section of the video every time they post a new video and existing popular videos.You can access the youtube channels link here.
            <CAPFellowshipLink />
        </>
    ]
];
export default function About() {

    return (
        <Box>
            <CenterFlexBox color='white.main' justifyContent='flex-start'>
                <AssignmentOutlinedIcon /> &nbsp;
                <Typography
                    fontSize={20} fontWeight={500} color='white.main'
                    component="h1"
                >
                    Learn More about the program
                </Typography>
            </CenterFlexBox>
            <br />
            <Typography fontSize={16} fontWeight={400} color='#ffffff'>
                We are looking for affiliate partners who can help us in the
                growth of our new product line of mock TOEFL tests.
                Your job is to be part of the product growth through our
                affiliate college learning program where you will onboard
                students from your colleges, communities, and friends who
                are either preparing for the TOEFL exam or are either looking
                to improve scores. Your fellowship would not only be limited
                to onboarding students but will include
                <ul>
                    <li>
                        Marketing the open TOEFL mock tests on various
                        social media channels through content strategy by
                        using Languify media kit of social media posts.
                        This strategy includes using the posts given by
                        Languify to be circulated in various communities
                        on WhatsApp, Instagram, and Facebook.
                    </li> <br />
                    <li>
                        Circulate the links with your friends and college
                        students on various social media platforms like
                        Instagram, Facebook, Whatsapp, and Telegram.
                    </li> <br />
                    <li>
                        You also have the responsibility to bring
                        additional affiliate partners & more to enhance
                        the growth of the product. You can share the
                        opportunity with your friends & college batchmates
                        by sharing the google form link with your friends
                        and college batchmates and adding your full name
                        referral section in the onboarding google form.
                        And guiding them to be part of this fellowship
                        and earn their stipend based on performance.
                    </li>
                </ul>
            </Typography>
            <Divider />
            <br />
            <Typography
                fontSize={20} fontWeight={500} color='white.main' component="h1"
            >
                Process
            </Typography>
            <Typography fontSize={16} fontWeight={400} color='white.main'>
                <ul>
                    <li>First copy the link which we have emailed you on your desktop, Mobile or Tablet.</li>
                    <li>After you have copied the link share the link in your close communities of students and friends.</li>
                    <li>The link that you have copied will be shared on the Following Streams.</li>
                </ul>
            </Typography>
            <br />
            <table style={styles.tableStyle}>
                <thead>
                    <tr style={styles.tableStyle}>
                        <td style={styles.tableStyle}>
                            Platform
                        </td>
                        <td style={styles.tableStyle}>
                            Channels
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {streams.map((stream, i) => (
                        <tr style={styles.tableStyle}>
                            <td style={styles.tableStyle}>
                                {stream[0]}
                            </td>
                            <td style={styles.tableStyle}>
                                {stream[1]}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <br />
            <Typography fontSize={16} fontWeight={400} color='white.main'>
                <ul>
                    <li>After you have copied the link you can share it with all the folks that you have shared the link to please complete the test.</li>
                    <li>After sharing the links and sharing the instructions to take the test ask a message after some time to know have they completed the tests or not.</li>
                    <li>If they forget the link or even after sharing the link unable to find the test you can share with them our website link or redirect them to languify.in where they can take the tests for free.</li>
                    <li>Make sure that they take the tests from your link only so that it is easy for us to track the students for your rewards.</li>
                </ul>
            </Typography>
        </Box>
    );
}