import React from 'react';
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";

import { LightButton } from "./Button";
import BackToTopButton from "./BackToTopButton";
import { useSnackbar } from 'contexts/SnackbarProvider';

const links = [
  { id: 1, name: "TOEFL", link: process.env.REACT_APP_TOEFL_URL },
  {
    id: 2, name: "In-Prep", link: process.env.REACT_APP_INPREP_URL,
  },
  {
    id: 5, name: "Careers", link: "https://forms.gle/h4b6tyWaUoz7p4Bv9",
    newPage: true
  },
  { id: 4, name: "FAQs", link: "/#faqs" },
];

const socialLinks = [
  {
    Icon: <LinkedInIcon color="primary" />,
    link: "https://www.linkedin.com/company/languify-in/"
  },
  {
    Icon: <InstagramIcon color="primary" sx={{ margin: "4px" }} />,
    link: "https://instagram.com/thelanguify?igshid=ack5ewhe6n96"
  },
  {
    Icon: <FacebookIcon color="primary" />,
    link: "https://www.facebook.com/Languify/"
  },
];

const useStyles = makeStyles(theme => ({
  parentContainer: {
    display: "flex", position: "relative", backgroundColor: "#001929",
    justifyContent: "space-around", flexWrap: "wrap", padding: "2em",
    marginTop: "1em",
    [theme.breakpoints.down(725)]: {
      flexDirection: "column",
    }
  },
  itemContainer: {
    maxWidth: 350,
    [theme.breakpoints.down(725)]: { maxWidth: "unset", padding: 0 }
  },
  mobileElement: {
    display: "none",
    [theme.breakpoints.down(725)]: { display: "block", }
  },
  divider: { width: "100%", margin: "2em auto" },
  button: {
    borderRadius: "4px", margin: "10px 0px"
  },
  header: {
    color: "white", fontSize: "1.3em", fontWeight: "bold",
    [theme.breakpoints.down(725)]: { fontSize: "1.4em" }
  }
}));

const NewTabLink = ({ newTab, href, children }) => {
  return (
    <Typography
      href={href} target={newTab ? "_blank" : "_self"}
      style={{
        color: "white", textDecoration: "none", margin: "0.5em 0",
        display: "block",
      }}
      component="a" rel="noreferrer" fontWeight="bold" fontSize="1.1vmax"
    >
      {children}
    </Typography>
  );
};

const Heading = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.header}>
      {children}
    </Typography>
  );
}

function Footer() {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const handleSubscribeClick = (e) => {
    e.preventDefault();
    e.target.reset();
    snackbar.showSnackbar("Subscribed successfully !!", "success");
  }

  return (
    <Box className={classes.parentContainer}>
      <BackToTopButton style={{ position: "absolute", top: -24, right: 32 }} />
      <Box className={classes.itemContainer}>
        <Heading>
          About Languify
        </Heading>
        <Typography color="white.main" m="1em 0" variant="body2">
          We are a team of highly motivated folks from IIT-Bombay and Carnegie Mellon University building Languify to solve the communication, assessment and hiring challenges of India and the World.
        </Typography>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {
            socialLinks.map((socialLink, index) => (
              <NewTabLink key={index} href={socialLink.link} children={socialLink.Icon} />
            ))
          }
        </Box>
      </Box>
      <hr className={`${classes.mobileElement} ${classes.divider}`} />
      <Box className={classes.itemContainer} padding="0 1em" id="contact-us">
        <Heading>
          Contact Us
        </Heading>
        <Box display="flex" alignItems="center" my="0.5em">
          <MailIcon sx={{ color: "#2DABFF" }} fontSize="medium" />
          <Typography
            color="white.main" variant="body2" fontWeight="normal" ml={2}
          >
            :  growth@languify.in
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" my="0.5em">
          <CallIcon sx={{ color: "#2DABFF" }} fontSize="medium" />
          <Typography color="white.main" variant="body2" fontWeight="normal"
            ml={2}
          >
            +91-9589952975
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" color="white.main">
          Subscribe to get updates
        </Typography>
        <form
          onSubmit={handleSubscribeClick}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <TextField
            size='small' autoComplete="email" variant="outlined" fullWidth
            sx={{
              color: "#9C9C9C", minWidth: 120, margin: 1, my: 2, maxWidth: 300,
              backgroundColor: "white.main", borderRadius: 2, border: "none",
            }}
            placeholder="Email Address" type="email" name="email"
          /> &nbsp;
          <LightButton
            variant="contained" width={150} className={classes.button}
            type="submit"
          >
            Subscribe
          </LightButton>
        </form>
      </Box>
      <hr className={`${classes.mobileElement} ${classes.divider}`} />
      <Box className={classes.itemContainer}>
        <Box display="flex" flexDirection="column">
          {
            links.map((link) => (
              <NewTabLink
                href={link.link}
                key={link.id}
                newTab={link.newPage}
              >
                {link.name}
              </NewTabLink>
            ))
          }
        </Box>
        <Typography
          fontWeight="normal"
          fontSize={"1.1vmax"}
          color="white"
          mt={3}
        >
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer;