import Client from "./_client";

export const getReferrals = async (user) => {

    const result = await new Client({
        path: `/referral?user=${user}`,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! unable to fetch referral link!"
        );
    }

    return result?.data;
};