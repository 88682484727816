import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import IconButton from "@mui/material/IconButton";
import Refresh from "@mui/icons-material/Refresh";
import Session from "utils/Session";

import InfoIconWithTooltip from "components/IconInfoWithTooltip";
import ReferralLink from "./ReferralLink";
import ShareOptions from "./ShareOptions";
import { useLoading, useSnackbar } from "contexts";
import { getReferrals } from "services/referralService";
import { getAnalytics } from "services/analyticsService";
import Toggle from "components/Toggle";

const useStyles = makeStyles((theme) => ({
    analytics: {
        justifyContent: 'space-evenly', flexWrap: "wrap", gap: 8,
    },
    analytic: {
        flexDirection: 'column', backgroundColor: '#0F3854', height: 160,
        borderRadius: 16, padding: 16, width: 250,
        justifyContent: 'space-around',
    }
}));

const defaultAnalytics = [
    {
        id: 'totalAssessments', value: 0, label: 'Total assessment',
        description: 'No of users who have completed the tests (30 mins or 3 hour tests) from your referral link'
    },
    {
        id: 'usersRegistered', value: 0, label: 'Users registered',
        description: 'No of users who have registered with us after completing the tests'
    },
    {
        id: 'totalClicks', value: 0, label: 'No. of clicks',
        description: 'No of students who have clicked on your referral link but may or may not completed the tests'
    },
    {
        id: 'paymentStatus', value: 'NA', paymentStatus: true,
        label: 'Payment status',
        description: 'Payments will be approved as per the T&C of languify'
    },
];

const TOGGLE_OPTIONS = [{ id: 'toefl', label: 'TOEFL' }, { id: 'ielts', label: 'IELTS' }];

export default function ReferralAndProgress() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const loading = useLoading();

    const [application, setApplication] = React.useState('toefl');
    const [referral, setReferral] = React.useState(null);
    const [analytics, setAnalytics] = React.useState(defaultAnalytics);
    const [refresh, setRefresh] = React.useState(0);

    React.useEffect(() => {
        (async () => {
            try {
                loading.show();

                const { referrals } = await getReferrals(Session.userId);

                setReferral(referrals?.pop());
            } catch (error) {
                console.error(error);

                snackbar.error("Uh Oh! Unable to fetch referral link")
            } finally {
                loading.hide();
            }
        })();
    }, []);

    React.useEffect(() => {
        if (referral) {
            (async () => {
                try {

                    loading.show();

                    const mockAnalytics = await getAnalytics(referral.code);
                    console.log(mockAnalytics);

                    const _analytics = defaultAnalytics.map((value) => {
                        return {
                            ...value,
                            value: mockAnalytics[value.id]
                        }
                    });

                    setAnalytics(_analytics);
                } catch (error) {
                    console.error(error);

                    snackbar.error("Uh Oh! unable to get details!")
                } finally {
                    loading.hide();
                }
            })();
        }
    }, [referral, refresh, application]);


    const referralLink = React.useMemo(() => referral ?
        `${process.env.REACT_APP_LANGUIFY_URL}?rc=${referral?.code}` :
        '', [referral]
    );

    const handleToggle = (value) => {
        setApplication(value.id);
    }

    return (
        <>
            <Box display='flex' flexDirection='column' my={2} position='relative'>
                <CenterFlexBox
                    fontFamily='inter' fontSize={20}
                    fontWeight={500} color='white.main'
                    justifyContent='flex-start'
                >
                    <InsertLinkOutlinedIcon /> &nbsp;
                    Your Unique Referral Link
                </CenterFlexBox>
            </Box>
            <br />
            <Typography
                fontFamily='inter' fontSize={20}
                fontWeight={500} color='white.main' mb={1}
            >
                Share Via
            </Typography>
            <ReferralLink referralLink={referralLink} />
            <br />
            <ShareOptions referralLink={referralLink} />
            <br />
            <Box display='flex' flexDirection='column' my={2} justifyContent='s'>
                <CenterFlexBox justifyContent='space-between'>
                    <CenterFlexBox
                        fontFamily='inter' fontSize={20}
                        fontWeight={500} color='white.main'
                        justifyContent='flex-start'
                    >
                        <EqualizerOutlinedIcon /> &nbsp;
                        Track Your Progress &nbsp;
                        <IconButton
                            title="Refresh" onClick={() => setRefresh(r => r + 1)}
                        >
                            <Refresh color="white" />
                        </IconButton>
                    </CenterFlexBox>

                </CenterFlexBox>
                <br />
                <CenterFlexBox className={classes.analytics}>
                    {analytics.map((
                        { label, value, paymentStatus, description }, index
                    ) => (
                        <CenterFlexBox key={index} className={classes.analytic}>
                            <Box
                                fontFamily='inter'
                                fontSize={paymentStatus ? 40 : 80}
                                fontWeight={700}
                                color='#2DABFF'
                            >
                                {value}
                            </Box>
                            <CenterFlexBox
                                fontFamily='inter'
                                fontSize={16}
                                fontWeight={500}
                                color='white.main'
                            >
                                {label} &nbsp;
                                <InfoIconWithTooltip
                                    placement="right"
                                    title={description}
                                />
                            </CenterFlexBox>
                        </CenterFlexBox>
                    ))}
                </CenterFlexBox>
                <br /><br /><br />
                <Typography
                    fontFamily='inter'
                    fontSize={16}
                    fontWeight={500}
                    color='white.main'
                    alignSelf='center'

                >
                    Incase of any problem/query contact us at &nbsp;
                    <span style={{ color: "#2DABFF" }}>growth@languify.in</span>
                </Typography>
            </Box>
        </>
    );
}