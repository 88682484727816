import React from 'react';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PasswordField from 'components/PasswordField';
import Button from 'components/Button';

import { useSnackbar } from 'contexts/SnackbarProvider';

import { resetPassword } from 'services/auth';
import AppShell from 'components/AppShell';
import { useLoading } from 'contexts';

const validationSchema = yup.object({
    password: yup
        .string('New password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Required'),
    confirmedPassword: yup
        .string('Confirm password')
        .oneOf([yup.ref('password'), null], "Does not match with above password!")
        .required('Required'),
});

const useStyles = makeStyles(theme => ({
    parentContainer: {
        height: '85vh'
    },
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: "2em",
        borderRadius: 8,
        maxWidth: 500,
        width: '60%',
        [theme.breakpoints.down("md")]: {
            width: "60%",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1em",
            width: "90%",
        },
    },
    resetButton: {
        marginTop: "3em", width: "100%", display: "flex",
        justifyContent: "space-between"

    }
}));

function ResetPassword() {
    const classes = useStyles();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const snackbar = useSnackbar();
    const loading = useLoading();

    const [values, setValues] = React.useState({
        password: '', confirmedPassword: '',
    });
    const [errors, setErrors] = React.useState(false);

    const handleSubmit = async (e) => {
        setErrors(false);
        e.preventDefault();

        validationSchema.validate(values, { abortEarly: false }).then(async _values => {
            try {
                loading.show();

                await resetPassword(_values.password, searchParams.get('at'));

                sessionStorage.setItem('password_reset', true);

                navigate('/login');
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error', true);
            }
        }).catch(reason => {
            setErrors(reason.inner.reduce((acc, ve) => {
                acc[ve.path] = ve.message;
                return acc;
            }, {}));
        }).finally(() => { loading.hide(); });
    };

    const handleChange = (e) => {
        setErrors(false);
        setValues((v) => ({ ...v, [e.target.name]: e.target.value }));
    };

    return (
        <AppShell>
            <Box className={classes.parentContainer} >
                <Box className={classes.container}>
                    <Typography variant='h4' color="white.main">
                        Reset Password
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <PasswordField
                            name="password"
                            title="New Password"
                            placeholder="New Password"
                            value={values.password}
                            onChange={handleChange}
                            error={Boolean(errors.password)}
                            inputProps={{ minLength: 8, maxLength: 20 }}
                        />
                        <Typography variant="caption" color="error"                        >
                            {errors.password}
                        </Typography>
                        <br />
                        <PasswordField
                            name="confirmedPassword"
                            title="Confirm Password"
                            placeholder="Confirm Password"
                            value={values.confirmedPassword}
                            onChange={handleChange}
                            error={Boolean(errors.confirmedPassword)}
                            inputProps={{ minLength: 8, maxLength: 20 }}
                        />
                        <Typography variant="caption" color="error"                        >
                            {errors.confirmedPassword}
                        </Typography>
                        <br />
                        <Box className={classes.resetButton}>
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={errors}
                            >
                                Reset
                            </Button>
                            <Button
                                variant='text'
                                type='button'
                                color="info"
                                disabled={errors}
                                onClick={() => navigate("/forgot-password")}
                            >
                                Change Account
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </AppShell>
    );
}

export default ResetPassword;