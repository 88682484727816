import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import MUIButton from "@mui/material/Button";
import Session from "utils/Session";

const useStyles = makeStyles(theme => ({
    header: {
        height: "80px",
        left: "0px",
        top: "0px",
        padding: "20px 0px",
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        backgroundColor: "#001929",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        [theme.breakpoints.down("md")]: {
            justifyContent: "space-between",
        }
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: 1400,
        margin: "0 auto",
        padding: "0 54px",
        [theme.breakpoints.down("md")]: {
            width: "85%"
        },
        [theme.breakpoints.down("md")]: {
            width: "95%",
            padding: 0
        }
    },
    desktopElement: {
        paddingRight: 100,
        [theme.breakpoints.down("md")]: {
            display: "flex"
        }
    },
    mobileElement: {
        display: "none",
        cursor: "pointer",
        height: "-webkit-fill-available",
        margin: "16px 32px",
        [theme.breakpoints.down("md")]: {
            display: "block",
        }
    },
    downloadButtonStyle: {
        borderRadius: "8px",
        padding: "15px 30px"
    },
    signinButtonStyle: {
        color: "white",
        textTransform: "none",
        marginRight: "40px",
        height: "10%"
    },
    logoContainer: {
        cursor: "pointer",
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            marginLeft: theme.spacing(0),
        },
    },
    logo: {
        [theme.breakpoints.down("md")]: {
            height: theme.spacing(4),
        },
    }
}));

const MenuItems = [
    { title: "About Us", link: "/about-us" },
    { title: "Login", link: "/login" },
    { title: "Register", link: "/" },
];

const MenuLink = ({ title, link, newTab, active }) => {
    return newTab ? (
        <MUIButton
            sx={{ color: "white.main", textTransform: "none" }}
            LinkComponent="a" href={link} target={"_blank"}
        >
            {title}
        </MUIButton>
    ) : (
        <Link
            to={link}
            style={{ textDecoration: "none", color: "white.main" }}
        >
            <MUIButton
                sx={{
                    color: active ? '#2DABFF' : 'white.main',
                    textTransform: "none"
                }}
            >
                {title}
            </MUIButton>
        </Link>
    );
}

function Header({ menuItems }) {

    const navigate = useNavigate();
    const location = useLocation();

    const classes = useStyles();

    const _menuItems = menuItems || MenuItems;

    const handleSignInOut = () => {
        if (Session.isLoggedIn()) {
            Session.clearSession();
        }
        navigate("/login");
    };

    return (
        <Box className={classes.header} >
            <Box className={classes.logoContainer}>
                <Link to="/" style={{ textDecoration: "none" }}>
                    <img
                        src={"https://assets.languify.in/images/lang-logo.svg"}
                        alt="languify" width={150} className={classes.logo}
                    />
                </Link>
            </Box>
            <Box
                flexGrow={1} display="flex" alignItems="center" gap={6}
                justifyContent="flex-end" className={classes.desktopElement}
            >
                {_menuItems.map((menu, index) => (
                    <MenuLink
                        key={index} title={menu.title}
                        link={menu.link}
                        newTab={menu.newPage}
                        active={(location.pathname === menu.link)}
                    />
                ))}
                {(Session.isLoggedIn()) &&
                    <MUIButton onClick={handleSignInOut}
                        sx={{ color: "white.main", textTransform: "none" }}
                    >
                        Logout
                    </MUIButton>}
            </Box>
        </Box>
    );
}

export default Header;