import create from "zustand";

const defaults = {
  application: 'ielts'
};

const useStore = create((set, get) => ({
  ...defaults,
  setApplication: (application) => (set({ application })),
  endTest: () => { },
  reset: () => set(defaults),
}),
);

export default useStore;