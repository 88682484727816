import { createTheme } from "@mui/material/styles";

export const themeOptions = {
  palette: {
    type: "dark",
    primary: { main: "#02569D", dark: "#053E64", darkest: "#001929" },
    subprimary: { main: "#2DABFF" },
    secondary: { main: "#01243B" },
    error: { main: "#f55446", light: "#EE2D2D" },
    divider: "rgba(133, 140, 144, 1)",
    success: { main: "#35e9a8" },
    white: { main: "#FFFFFF" },
    errorLight: { main: "#EE2D2D" },
  },
  typography: { fontFamily: 'Inter' }
};

const theme = createTheme(themeOptions);

export default theme;
