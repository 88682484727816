import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoading, useSnackbar } from "contexts";
import { LoginSchema } from "schemas";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AboutAmbassador from "components/AboutAmbassador";
import AppShell from "components/AppShell";
import Button from "components/Button";
import Divider from "@mui/material/Divider";
import TextField from 'components/TextField';
import PasswordField from "components/PasswordField";
import { signIn } from "services";
import Session from "utils/Session";

const formFieldSX = { width: '300px' };

const InitialValues = {
  email: '', password: ''
};

const MenuItems = [
  { title: "About Us", link: "/about-us" },
  { title: "Register", link: "/" },
];

function Login() {
  const loading = useLoading();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [values, setValues] = useState(InitialValues);
  const [errors, setErrors] = useState({});

  const getFieldProps = (name) => ({
    id: name,
    name: name,
    error: errors[name],
    sx: formFieldSX,
    inputProps: { autoComplete: 'none' },
    onChange: (e) => setValues((v) => ({ ...v, [name]: e.target.value }))
  });

  const handleLogin = async () => {
    try {
      loading.show();

      await signIn(values);

      snackbar.success("Logged in successfully");

      navigate('/home');
    } catch (error) {
      console.error(error);

      snackbar.error("Uh Oh! password or username may be incorrect!");
    } finally {
      loading.hide();
    }
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setErrors({});

      const formData = new FormData(e.target);

      const values = {};
      formData.forEach((value, key) => {
        values[key] = value;
      });

      const { error, value } = LoginSchema.validate(values, { abortEarly: false });

      if (error) {
        setErrors(error.details.reduce((acc, curr) => {
          acc[curr.path.toString()] = true;
          return acc;
        }, {}));

        console.error(error)
      } else {
        setValues((v) => ({ ...v, ...value }));

        handleLogin();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  React.useEffect(() => {
    if (Session.isLoggedIn())
      navigate('/home');
  });

  return (
    <AppShell menuItems={MenuItems}>
      <AboutAmbassador />
      <br /><br />
      <Divider />
      <Box
        display="flex" py={2} flexDirection="column"
        alignItems="flex-start" component="form"
        onSubmit={handleSubmit} maxWidth={500}
      >
        <Typography
          fontSize={22} fontWeight={700}
          color="#2DABFF" mb={1}
        >
          LOGIN
        </Typography>

        <TextField
          {...getFieldProps('email')}
          placeholder="Email*"
          sx={{ width: '100%' }}
        />
        <PasswordField
          {...getFieldProps('password')}
          placeholder="Password*"
          sx={{ width: '100%' }}
        />

        <br />
        <Box display="flex" alignItems="flex-end" width="100%" justifyContent="space-between">
          <Button
            id="login-button" type="submit"
            variant="contained" style={{ padding: '8px 5vw' }}
          >
            Submit
          </Button>
          <Typography
            variant="subtitle4"
            alignSelf="flex-end"
          >
            <Link to="/forgot-password" style={{ color: "white", textDecoration: "underline" }}>
              forgot password?
            </Link>
          </Typography>
        </Box>
      </Box>
    </AppShell>
  );
}

export default Login;