import makeStyles from "@mui/styles/makeStyles";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import { Table } from "components/Table";

const useStyle = makeStyles({
    table: {
        bordeCollapse: 'collapse',
        borderColor: 'transparent',
        borderRadius: '1em',
        borderSpacing: 0,
        tableLayout: 'fixed',
        width: '100%',
        backgroundColor: 'white !important'
    },
    td: {
        width: '30%',
        border: 'none',
        color: 'black',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 500,
        padding: '14px 16px',
        textAlign: 'center',
        verticalAlign: 'top',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        '&:last-child': {
            borderRight: 'none',
        }
    },
    th: {
        width: '30%',
        maxWidth: '30%',
        minWidth: '30%',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid black',
        borderRight: '1px solid black',
        border: 'none',
        color: 'black',
        fontFamily: 'inherit',
        fontSize: '16px',
        fontWeight: 700,
        padding: '14px 5px',
        overflow: 'hidden',
        wordBreak: 'normal',
        verticalAlign: 'middle',
        '&:first-child': {
            borderRadius: '1em 0 0 0'
        },
        '&:last-child': {
            borderRadius: '0 1em 0 0',
        },
    }
});


const cols = ['Number of students', 'Amount paid per student', 'Amount earned monthly'];

const rows = [
    {
        numberOfStudent: '0-50',
        amountPaidPerStudent: '0',
        amountEarnedMonthly: 'First 50 students are not considered in the quota.'
    },
    {
        numberOfStudent: '50-100',
        amountPaidPerStudent: '60',
        amountEarnedMonthly: 'After You have onboarded more than 50 students who have completed the free tests you will be paid for the students who are coming after your 50 students. So if you onboarded 480 students which is (480 - 50) * 60 = 25K INR'
    },
    {
        numberOfStudent: '500-1000',
        amountPaidPerStudent: '65',
        amountEarnedMonthly: 'If you have onboarded more than 500 students you will be paid for the 450 students which is (500 - 50) * 65 = 29k INR'
    },
    {
        numberOfStudent: '1000+',
        amountPaidPerStudent: '100',
        amountEarnedMonthly: 'If you have onboarded 1000 students you will be paid for 950 students which is (1000 - 50) * 100 = 95K INR'
    }
];

export default function Incentives() {
    const classes = useStyle();

    return (
        <>
            <CenterFlexBox
                fontFamily='inter' fontSize={20}
                fontWeight={500} color='#ffffff'
                justifyContent='flex-start'
            >
                <AutoAwesomeIcon /> &nbsp;
                Incentives
            </CenterFlexBox>
            <br />
            <Table
                columns={cols} rows={rows} tableClass={classes.table}
                tdClass={classes.td} thClass={classes.th}
            />
            <br />

            <Typography
                fontFamily='inter' fontSize={16}
                fontWeight={400} color='#ffffff'
            >
                <ul>
                    <li>
                        In order for you to receive incentives a minimum of 50 students should be onboarded who have completed the tests & affiliate partners that you will onboard from your circle monthly basis.
                    </li>
                    <li>You will be only paid for the students who have completed the full test which is the 30 minutes test & 3 Hour full-length test.</li>
                    <li>You will be paid for the referral generated by you if the test is given by a real person and with real details asked at the end of the test for verification purposes.</li>
                    <li>If your earnings and incentives in any case cross more than 2.4 Lakhs INR then TDS will be deducted.</li>
                    <li>Your incentives will be transferred to you at the end of every quarter ( quarter = 3 months financial quarter) subject to Languify`s vendor payment.</li>
                    <li>Your affiliation is performance-based the more students you bring the more amount of rewards you will earn.    </li>
                </ul>
            </Typography>
            <Typography
                fontFamily='inter' fontSize={20}
                fontWeight={500} color='#ffffff'
            >
                Additional Incentives
            </Typography>
            <Typography
                fontFamily='inter' fontSize={16}
                fontWeight={400} color='#ffffff'
            >
                <ul>
                    <li>
                        Letter of recommendation - For the top 1 % of students who are the best performers, they will get the LOR by the CEO of languify which will describe your incredible contribution towards this program which will help you in your professional journey. This LOR will clearly state that you worked with us as an intern - LOR template.
                    </li>
                    <li>
                        Internship certificate - This certificate will be given to all the members who have onboarded more than 50 students who have completed the tests for 3 months minimum. Template link
                    </li>
                    <li>
                        Internships - Top 1 % will also be awarded a chance to Intern at languify to work on cutting-edge ML / AI products in the domain of UI/UX, web development, full stack development, and product management.
                    </li>
                </ul>
            </Typography>
            <Typography
                fontFamily='inter' fontSize={20}
                fontWeight={500} color='#ffffff'
            >
                Terms & Conditions
            </Typography>
            <Typography
                fontFamily='inter' fontSize={16}
                fontWeight={400} color='#ffffff'
            >
                <ul>
                    <li>You have to spend a minimum 30 minutes on working for this campaign.</li>
                    <li>This program is not a time period-bound program you can participate in it as long as you want.</li>
                    <li>Thier is no limit on the number of students you want to onboard. You can onboard any amount of students through your referral link.</li>
                    <li>Based on market trends we may ask you to circulate the links on some additional platforms.</li>
                    <li>You cannot use virtual numbers,bots or scripts to register the users. If any violation is founded that will directly result in your disqualification from the program.</li>
                    <li>The verification of the students that you have onboarded will be done through our internal systems proof of screenshots,Texts,emails & others will work.</li>
                    <li>In the event of a payment that we have not received from any of the Service Companies due to a dispute or any other reason, no payment will be made to the student until such payment has been received by the company.</li>
                    <li>For any matter, final desicion will be taken by Languify Management and will be considered final.</li>
                    <li>We reserve the right to change any clause at any point of time. You are required to keep yourself updated of the clauses.</li>
                    <li>For further details you may contact - growth@languify.in.</li>
                </ul>
            </Typography>
        </>
    );
}