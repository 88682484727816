import React from "react";
import Box from '@mui/material/Box';
import Header from "./Header";
import Footer from "./Footer";

function AppShell({ children, hideFooter, menuItems, light }) {
  return (
    <Box
      overflow='hidden'
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      backgroundColor={light ? "#fff" : '#001929'}
    >
      <Header menuItems={menuItems} />
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}
        minHeight={"calc(100vh - 80px)"}
        mx={'min(40px,5vw)'}
        pt={4}
      >
        {children}
      </Box>
      {!hideFooter && <Footer />}
    </Box>
  );
}

export default AppShell;