import { useEffect, useCallback, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import AboutAmbassador from "components/AboutAmbassador";
import AppShell from "components/AppShell";
import Button from "components/Button";
import CitySelect from "components/CitySelect";
import CountryCodeSelect from "components/CountryCodeSelect";
import OTPDialog from "components/OTPDialog";
import PasswordField from "components/PasswordField";
import TextField from 'components/TextField';
import { useLoading, useSnackbar } from "contexts";
import { signUp } from "services";
import { RegistrationSchema } from "schemas";
import { OTPAuth } from "utils";
import Session from "utils/Session";

const formFieldSX = { width: '300px' };

const MenuItems = [
    { title: "About Us", link: "/about-us" },
    { title: "Login", link: "/login" },
];

const InitialValues = {
    name: '', email: '', phone: '', city: '', countryCode: '91', otp: '', password: ''
};

export default function Register() {
    const loading = useLoading();
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [validateOTP, setValidateOTP] = useState(false);
    const [enableValidateOTP, setEnableValidateOTP] = useState(false);
    const [resent, setResent] = useState(0);
    const [open, setOpen] = useState(false);

    const [values, setValues] = useState(InitialValues);
    const [errors, setErrors] = useState({});

    const otpAuth = useRef(new OTPAuth());

    const onOTPChange = (_otp) => {
        const otp = _otp.trim();

        setValues((v) => ({ ...v, otp }));

        if (otp.length === 6) {
            setEnableValidateOTP(true);
            onOTPSubmit(otp);
        }
    };

    const onOTPSubmit = async (otp) => {
        try {
            const otpToVerify = otp || values.otp;
            loading.show();

            let user;
            let verificationFailed = false;
            try {
                user = await otpAuth.current.verifyOTP(otpToVerify);

            } catch (error) {
                setResent(1);
                console.error(error);
                setValues((v) => ({ ...v, otp: '' }));
                verificationFailed = true;
                snackbar.error("Uh Oh! You have entered an incorrect OTP");
            }

            if (!verificationFailed) {
                await signUp({ ...values, guid: user.uid });

                snackbar.success("Successfully registed!");

                setOpen(false);

                navigate('/home');
            }
        } catch (error) {
            console.error(error);

            snackbar.error("Uh Oh! Something went wrong!");
        } finally {
            loading.hide();
        }
    };

    const resendOtp = () => {
        setResent(0);
        sendOTP();
    }

    const sendOTP = useCallback(() => {
        loading.show();

        const invokeSendOTP = () => {
            console.log("Sending otp to...", values.countryCode.concat(values.phone));

            otpAuth.current.sendOTP("+" + values.countryCode.concat(values.phone))
                .then(() => {
                    setValidateOTP(true);
                    setValues((v) => ({ ...v, otp: '' }));
                    setOpen(true);
                    snackbar.success("OTP sent successfully")
                })
                .catch((e) => {
                    console.error(e);
                    snackbar.error("Something went wrong. Try sending Again!!")
                }).finally(() => loading.hide());
        };

        if (otpAuth.current.appVerifier) invokeSendOTP();
        else {
            otpAuth.current.setRecaptchaVerifier();
            invokeSendOTP();
        }
    }, [values]);

    const handleRegister = async (e) => {
        try {
            e.preventDefault();

            setErrors({});

            const formData = new FormData(e.target);

            const values = {};
            formData.forEach((value, key) => {
                values[key] = value;
            });

            const { error, value } = RegistrationSchema.validate(values, { abortEarly: false });

            if (error) {
                setErrors(error.details.reduce((acc, curr) => {
                    acc[curr.path.toString()] = true;
                    return acc;
                }, {}));

                console.error(error)
            } else {
                setValues((v) => ({ ...v, ...value }));

                sendOTP();
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const getFieldProps = (name) => ({
        id: name,
        name: name,
        error: errors[name],
        sx: formFieldSX,
        inputProps: { autoComplete: 'none' },
        onChange: (e) => setValues((v) => ({ ...v, [name]: e.target.value }))
    });

    useEffect(() => {
        if (Session.isLoggedIn())
            navigate('/home');
    });

    return (
        <AppShell menuItems={MenuItems}>
            <AboutAmbassador />
            <br /><br />
            <Divider />
            <br /><br />
            <Box
                display="flex" py={2} maxWidth={500}
                flexDirection="column" alignItems="flex-start" component="form"
                onSubmit={handleRegister}
            >

                <Typography fontSize={22} fontWeight={700} color="#2DABFF" mb={1}>
                    REGISTER NOW
                </Typography>
                <TextField {...getFieldProps('name')} placeholder="Your name*" sx={{ width: '100%' }} />
                <TextField {...getFieldProps('email')} placeholder="Email*" sx={{ width: '100%' }} />
                <CitySelect error={!!errors['city']} width='100%' />
                <Box display='flex' justifyContent='space-between' width='100%'>
                    <CountryCodeSelect />
                    <TextField
                        {...getFieldProps('phone')} placeholder="Mobile number*"
                        sx={{ width: 'calc(100% - 82px)' }}
                    />
                </Box>
                <PasswordField {...getFieldProps('password')} placeholder="Password*" sx={{ width: '100%' }} />
                <br />
                <Box display="flex" alignItems="flex-end" width="100%" justifyContent="space-between">
                    <Button
                        id="login-button" type="submit" variant="contained"
                        style={{ padding: '8px 5vw' }}
                    >
                        Submit
                    </Button>
                    <Typography
                        variant="subtitle4"
                        alignSelf="flex-end"
                    >
                        <Link to="/login" style={{ color: "white", textDecoration: "underline" }}>
                            Already have an account?
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <OTPDialog
                open={open} onClose={handleClose}
                otp={values.otp}
                setValidateOTP={setValidateOTP}
                onOTPChange={onOTPChange}
                onOTPSubmit={onOTPSubmit}
                phone={values.phone}
                resent={resent}
                resendOtp={resendOtp}
                validateOTP={validateOTP}
                enableValidateOTP={enableValidateOTP}
            />
            <div id="recaptcha-container"></div>
            <br /><br />
            <Divider />
        </AppShell>
    );
}