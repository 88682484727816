import Session from "../utils/Session";
import Client from "./_client";

export const restoreSession = async () => {
  const result = await new Client({
    path: "/auth/restore"
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to restore you session. Please sign in.');
  } else {
    if (!result.data.user.email) result.data.guest = true;
    Session.onRestoreSession(result.data);
    return result;
  }
};

export const verifyToken = async (token) => {
  const result = await new Client({
    path: "/signin/verify-token",
    payload: { token }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
}

export const signUp = async ({ name, email, city, guid, password }) => {
  const result = await new Client({
    path: "/signup?withPhone=true",
    payload: { name, email, city, guid, password }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  } else {
    if (result?.data?.verified) {
      Session.onCreateSession(result.data);
    }

    return result?.data;
  }
}

export const signIn = async (user) => {
  const result = await new Client({
    path: "/signin",
    payload: {
      email: user.email,
      password: user.password
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
}

export const signOut = async () => {
  Session.clearSession();
};

export const generateResetPasswordLink = async (email) => {
  const result = await new Client({
    path: "/auth/reset-password-link",
    payload: {
      email: email,
      redirectUrl: process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL,
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  }
  return result.message;
}

export const resetPassword = async (password, token) => {
  const result = await new Client({
    path: "/auth/reset-password",
    payload: {
      token,
      password
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  }
  return result.message;
}