import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles({
    table: {
        bordeCollapse: 'collapse',
        borderColor: 'transparent',
        borderRadius: '1em',
        borderSpacing: 0,
        boxShadow: '0 0 8px 4px #f1ebeb',
        tableLayout: 'fixed',
        width: '100%'
    },
    td: {
        width: '30%',
        border: 'none',
        color: 'black',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 500,
        padding: '12px 16px',
        textAlign: 'center',
        verticalAlign: 'top',
        borderRight: '1px solid black',
        '&:last-child': {
            borderRight: 'none',
        }
    },
    th: {
        width: '30%',
        maxWidth: '30%',
        minWidth: '30%',
        backgroundColor: '#02569d',
        border: 'none',
        color: '#fff',
        fontFamily: 'inherit',
        fontSize: '16px',
        fontWeight: 700,
        padding: '10px 5px',
        overflow: 'hidden',
        wordBreak: 'normal',
        verticalAlign: 'middle',
        '&:first-child': {
            borderRadius: '1em 0 0 0'
        },
        '&:last-child': {
            borderRadius: '0 1em 0 0',
        },
    }
});

export function Table({
    rows = [],
    columns = [],
    tableClass,
    thClass,
    tdClass,
    ...props
}) {
    const classes = useStyle();

    return (
        <Box {...props}>
            <table className={tableClass || classes.table}>
                <tbody>
                    <tr>
                        {
                            columns.map((header, index) =>
                                <th className={thClass || classes.th} key={index}>
                                    {header}
                                </th>
                            )
                        }
                    </tr>
                    {
                        rows.map((row, index) => (
                            <tr key={index}>
                                {
                                    Object.values(row).map((value, index) => (
                                        <td key={index}
                                            className={tdClass || classes.td}
                                        >
                                            {value}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </Box>
    );
}