import React, { useState } from 'react';
import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import AppShell from 'components/AppShell';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { useLoading, useSnackbar } from 'contexts';

import { generateResetPasswordLink } from 'services/auth';


const emailValidationSchema = yup
    .string('Enter your email')
    .email('Invalid email')
    .required('Required');

const useStyles = makeStyles(theme => ({
    rightContainer: {
        height: "100%",
        width: "50%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "25vw",
        flexDirection: "row-reverse"
    },
    googleImg: {
        cursor: "pointer",
    },
}));

function ForgotPassword() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const loading = useLoading();
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [linkSent, setLinkSent] = useState(false);


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            loading.show();

            setError(false);

            const _email = await emailValidationSchema.validate(email);

            const result = await generateResetPasswordLink(_email);

            snackbar.showSnackbar(result, 'success', true);

            setLinkSent(true);
        } catch (error) {
            setError(error.message);
            snackbar.showSnackbar(error.message, 'error');
        } finally {
            loading.hide();
        }
    };

    return (
        <AppShell>
            <Box height="100%" width="100%" pl={15} py={12}>
                <form onSubmit={handleSubmit}>
                    <Typography variant="h4" fontWeight="700" color="white.main">
                        Forgot Password?
                    </Typography>
                    <Typography variant="h6" fontWeight="500" color="white.main">
                        send reset link
                    </Typography>

                    <Box
                        display="flex" flexDirection="column"
                        width="fit-content" mt={4}
                    >
                        <TextField
                            autoFocus
                            placeholder="abc@example.com"
                            style={{ "width": "25vw" }}
                            id="email"
                            name="email"
                            title="email"
                            type="email"
                            value={email}
                            onChange={(e) => { setError(false); setEmail(e.target.value) }}
                            error={!!error}
                        />
                        <Typography
                            variant="caption" color="error"
                        >{error}</Typography>
                        {linkSent && (
                            <Typography
                                variant="subtitle4"
                                alignSelf="flex-end"
                                mt={1}
                                color="white.main"
                            >
                                Didn’t recieved link?
                                <Typography
                                    variant="subtitle4"
                                    onClick={handleSubmit}
                                    sx={{
                                        ml: 1, color: "#02569D",
                                        cursor: "pointer"
                                    }}
                                >
                                    Resend
                                </Typography>
                            </Typography>
                        )}
                    </Box>

                    <Box className={classes.buttonContainer} mt={4} mb={2}>
                        <Button
                            size="medium"
                            sx={{ height: "34px" }}
                            type="submit"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </Box>
                </form>
            </Box>
        </AppShell>
    );
}

export default ForgotPassword;
