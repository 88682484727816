import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MUITextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "8px 0",
    borderRadius: "6px",
    fontSize: 12,
    fontWeight: "normal",
    background: "#F1F2F6",
    "& .MuiInputLabel-root": {
      fontSize: "12px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "6px",
      backgroundColor: "#F1F2F6",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#F1F2F6",
      borderRadius: "6px",
      fontSize: 12,
      fontWeight: "normal",
      '& fieldset': {
        borderRadius: "6px",
        borderColor: "transparent",
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "'Inter', sans-serif",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",

      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
  },
}));

function PasswordField(props) {
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <MUITextField
      className={classes.root}
      size='small'
      variant="outlined"
      fullWidth
      type={showPassword ? "text" : "password"}
      sx={{
        minWidth: 180,
        ...(props.sx ? { ...props.sx } : {}),
      }}
      InputLabelProps={{ style: { fontSize: "1rem", fontWeight: 200 } }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
      {...props}
    />

  );
};

export default PasswordField;