import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function AboutAmbassador(props) {
  return (
    <Box width='100%' height={'fit-content'}>
      <Typography fontFamily='inter' fontSize={24} fontWeight={600} color='#ffffff' mb={1}>
        Who is an affiliate partner?
      </Typography>
      <Typography fontFamily='inter' fontSize={15} fontWeight={400} color='#ffffff'>
        We are looking for affiliate partners who can help us in the
        growth of our new product line of mock TOEFL tests.
        Your job is to be part of the product growth through our
        affiliate college learning program where you will onboard
        students from your colleges, communities, and friends who
        are either preparing for the TOEFL exam or are either
        looking to improve scores. Your fellowship would not
        only be limited to onboarding students but will include
        <ul>
          <li>
            Marketing the open TOEFL mock tests on various
            social media channels through   content strategy
            by using Languify media kit of social media posts.
            This strategy includes using the posts given by
            Languify to be circulated in various communities
            on WhatsApp, Instagram, and Facebook.
          </li>
          <br />
          <li>
            Circulate the links with your friends and college
            students on various social media platforms like
            Instagram, Facebook, Whatsapp, and Telegram.
          </li>
          <br />
          <li>
            You also have the responsibility to bring additional
            affiliate partners & more to enhance the growth of the product.
            You can share the opportunity with your friends &
            college batchmates by sharing the google form link
            with your friends and college batchmates and adding
            your full name referral section in the onboarding google form.
            And guiding them to be part of this fellowship and
            earn their stipend based on performance. Google form link
          </li>
        </ul>
      </Typography>
    </Box>
  );
}

export default AboutAmbassador;