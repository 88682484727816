import { encrypt, decrypt } from "./cryptoUtil";
import LogRocket from 'logrocket';

const languifyIsCredentials = Symbol("languifyIsCredentials");

class Session {
  userId = "";
  user = ""
  accessToken = "";
  accessTokenExpiry = Date.now();
  refreshToken = "";
  refreshTokenExpiry = Date.now();

  _setUser(user) {
    this.userId = user._id;
    this.user = user;

    localStorage.setItem('guestId', this.userId);

    if (process.env.REACT_APP_STAGE === 'prod' &&
      process.env.NODE_ENV === 'production') {
      if (this.isLoggedIn()) {
        LogRocket.identify(this.userId, { registered: false });
      } else {
        LogRocket.identify(this.userId, {
          email: this.user.email, phone: this.user.phone, registered: true
        });
      }
    }
  }

  _unsetUser() {
    this.userId = "";
    this.user = null;
  }

  onCreateSession({ user, tokens, guest = false }) {
    this._setUser(user);
    this.accessToken = tokens.accessToken;
    this.accessTokenExpiry = new Date(tokens.accessExpireAt).getTime();
    this.refreshToken = tokens.refreshToken;
    this.refreshTokenExpiry = new Date(tokens.refreshExpireAt).getTime();
    localStorage.setItem("languifyIsLoggedIn", (!guest).toString());
    localStorage.setItem("languifyRefreshTokenExpiry", this.refreshTokenExpiry.toString());
    localStorage.setItem("languifyRefreshToken", this.refreshToken);
  }

  onRestoreSession({ accessToken, accessExpireAt, user, guest = false }) {
    this._setUser(user);
    this.accessToken = accessToken;
    this.accessTokenExpiry = new Date(accessExpireAt).getTime();
    localStorage.setItem("languifyIsLoggedIn", (!guest).toString());
  }

  clearSession() {
    try {
      this.accessToken = "";
      this.accessTokenExpiry = Date.now();
      this.refreshToken = "";
      this.refreshTokenExpiry = Date.now();
      localStorage.removeItem("languifyIsLoggedIn", false.toString());
      localStorage.removeItem("languifyRefreshTokenExpiry");
      localStorage.removeItem("languifyRefreshToken");
      this.unRememberMe();
      this._unsetUser();
    } catch (error) {
      console.log(error);
    }
  }

  isLoggedIn() {
    return JSON.parse(localStorage.getItem("languifyIsLoggedIn"));
  }

  isRemembered() {
    if (languifyIsCredentials.description) {
      const credentialsRemembered = localStorage.getItem(languifyIsCredentials.description);
      return Boolean(credentialsRemembered) ? decrypt(credentialsRemembered) : false;
    } else return false;
  }

  rememberMe({ phone, password }) {
    if (languifyIsCredentials.description)
      localStorage.setItem(languifyIsCredentials.description, encrypt({ phone, password }));
  }

  unRememberMe() {
    if (languifyIsCredentials.description)
      localStorage.removeItem(languifyIsCredentials.description);
  }
}

export default new Session();