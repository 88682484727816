import Client from "./_client";

export const getAnalytics = async (referralCode) => {

    const result = await new Client({
        path: `/analytics/ambassador?referralCode=${referralCode}`,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! unable to fetch analytics!"
        );
    }

    return result?.data;
};