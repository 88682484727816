import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import { CopyButton } from 'components/Button';

function ReferralLink({ referralLink }) {
  const textRef = React.useRef();

  return (
    <Box
      display='flex'
      justifyContent='space-between' p={1}
      backgroundColor='rgba(73, 182, 255, 0.2)'
      border='1px solid #2DABFF'
      borderRadius={1}
      alignItems="center"
    >
      <Typography
        fontFamily='inter' fontSize={'min(3vw,15px)'}
        fontWeight={500} color='#ffffff'
        ref={textRef}
      >
        {referralLink}
      </Typography>
      <CenterFlexBox
        fontFamily='inter' fontSize={15}
        fontWeight={500} color='white.main'
        style={{ cursor: 'pointer' }}
      >
        <CopyButton
          content={referralLink}
          iconProps={{ color: "white" }}
          textRef={textRef}
        />
      </CenterFlexBox>
    </Box>
  );
}

export default ReferralLink;