import React from 'react';
import AppShell from '../components/AppShell';
import ImagesFlexbox from '../components/ImagesFlexbox';
import InfoDiv from '../components/InfoDiv';

const team = [
  {
    url: 'https://assets.languify.in/images/lokap.png',
    name: 'Lokap Sahu',
    title: 'CEO & Co-Founder IITB'
  },
  {
    url: 'https://assets.languify.in/images/mohak.png',
    name: 'Mohak Sahu',
    title: 'CTO AI IITB'
  },
  {
    url: 'https://assets.languify.in/images/shivam-black.png',
    name: 'Shivam Sahu',
    title: 'CDO'
  },
  {
    url: 'https://assets.languify.in/images/pushpendra.jpg',
    name: 'Pushpendra Upadhyay',
    title: 'Engineering Manager'
  },
  {
    url: 'https://assets.languify.in/images/shourya.png',
    name: 'Shourya Verma',
    title: 'Associate Product Manager'
  },
  {
    url: 'https://assets.languify.in/images/arjun-deshmukh.png',
    name: 'Arjun Deshmukh',
    title: 'Product & Design'
  },
  {
    url: 'https://assets.languify.in/images/mahmood.png',
    name: 'Mahmood Alam Syed',
    title: 'Growth & Outreach'
  },
  {
    url: 'https://assets.languify.in/images/soha-bhatia.png',
    name: 'Soha Bhatia',
    title: 'Full Stack Trainee'
  },
  {
    url: 'https://assets.languify.in/images/pratima-singh.png',
    name: 'Pratima Singh',
    title: 'Full Stack Trainee'
  },
];

const mentor = [
  {
    url: 'https://assets.languify.in/images/sachin-paranjape.png',
    name: 'Sachin Paranjape',
    title: 'IITR Product Advisor'
  },
  {
    url: 'https://assets.languify.in/images/prof-bhargav.png',
    name: 'Parag Bharagav',
    title: 'Professor, IITB'
  },
  {
    url: 'https://assets.languify.in/images/anuj.png',
    name: 'Anuj Sharma',
    title: 'CEO, Alsisar'
  },
  {
    url: 'https://assets.languify.in/images/ranjeet.png',
    name: 'Ranjeet Gakhare',
    title: 'Associate Director, FlowBiz'
  },
  {
    url: 'https://assets.languify.in/images/sachin-mentor.jpg',
    name: 'Sachin Bhat',
    title: 'Product & Strategy'
  },
  {
    url: 'https://assets.languify.in/images/projasnit.png',
    name: 'Prof. Jasnit',
    title: 'Content Development'
  }
]

function AboutUs() {
  return (
    <AppShell light>
      <InfoDiv
        largeWidth='90%'
        smallWidth='80%'
        title='About Us'
        titleVariant='h1'
        subtitle="Languify’s team is all about innovation and 
        commitment for their mission."
        subtitleVariant='h4'
        textAlign='center'
        marginTop="7em"
        marginBottom="4em"
        smallMargin="2em"
      />
      <ImagesFlexbox flexboxTitle={'Meet Our Team'} imagesInfo={team} width={'65%'} />
      <ImagesFlexbox flexboxTitle={'Mentors'} imagesInfo={mentor} width={'90%'} />
    </AppShell>
  );
}

export default AboutUs;
