import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import OTPInput from 'components/OTPInput';
import Button from 'components/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const PrivacyPolicy = "https://assets.languify.in/api-docs/Languify-Privacy-Policy.pdf";

export default function OTPDialog({
    otp,
    open = false,
    phone,
    resent,
    enableValidateOTP,
    resendOtp,
    onOTPSubmit,
    onClose,
    validateOTP,
    setValidateOTP,
    onOTPChange
}) {

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            disableEscapeKeyDown={true}
        >
            <DialogContent>
                <Box
                    display="flex" mx='auto' py={2} px={5}
                    flexDirection="column" alignItems="center"
                >
                    <Typography
                        fontSize={20} fontWeight={700} textAlign="center"
                    >
                        Please enter the OTP to verify mobile number
                    </Typography>
                    <Typography fontSize={14} fontWeight={500} color="#00000099" mt={1}>
                        A code has been sent to {phone} &nbsp;
                        <span style={{
                            color: '#02569D',
                            borderBottom: '1px solid',
                            cursor: 'pointer'
                        }}
                            onClick={handleClose}
                        >
                            Change Number
                        </span>
                    </Typography>
                    <br /><br />
                    <OTPInput
                        value={otp}
                        onChange={onOTPChange}
                    />
                    <br /><br />
                    <Button
                        disabled={!enableValidateOTP}
                        variant="contained"
                        onClick={() => onOTPSubmit}
                    >
                        Validate
                    </Button>
                    <Typography fontSize={14} fontWeight={500} color="#00000099" mt={1}>
                        Didn’t get the code? &nbsp;
                        <Button
                            variant="text" style={{ fontSize: 14, padding: "4px 8px" }}
                            onClick={resent > 0 ? resendOtp : () => setValidateOTP(false)}
                        >
                            {resent > 0 ?
                                `Resend` :
                                "Change the phone number"
                            }
                        </Button>
                    </Typography>

                    <Divider width="100%" light />
                    <Typography variant='caption' color="#00000099" mt={1} textAlign="center">
                        By continuing, you agree to our&nbsp;
                        <Typography
                            component="a" variant='caption' color="#02569D" target="_blank"
                            href={PrivacyPolicy} style={{ textDecoration: 'none' }}
                            rel="noreferrer"
                        >
                            Terms and Conditions
                        </Typography>
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
}