import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import About from "./About";
import AppShell from "components/AppShell";
import CenterFlexBox from "components/CenterFlexBox";
import Faq from "./Faq";
import Incentives from "./Incentives";
import ProgressAndTrack from "./ReferralAndProgress";
import MediaKit from "./MediaKit";
import Session from "utils/Session";

const useStyles = makeStyles((theme) => ({
    heading: {
        display: 'flex', justifyContent: 'space-between',
        [theme.breakpoints.down("md")]: {
            flexDirection: "column", alignItems: "baseline",
        }
    },
    welcome: {
        fontFamily: 'inter',
        color: '#ffffff',
        fontSize: "min(3vw,28px)",
        fontWeight: 700,
    },
    emailContainer: {
        fontFamily: 'inter', wordBreak: "break-word", fontSize: "min(2.5vw,18px)",
        fontWeight: 500, color: 'rgba(255, 255, 255, 0.6)'
    }
}));

const MenuItems = [
    { title: "Home", link: "/home" },
    { title: "About", link: "/home/about" },
    { title: "Incentives", link: "/home/incentives" },
    { title: "MediaKit", link: "/home/mediakit" },
    { title: "FAQs", link: "/home/faq" },
];

export default function Home() {
    return (
        <Routes>
            <Route path="/" element={<HomeLayout />}>
                <Route index element={<ProgressAndTrack />} />
                <Route
                    path="faq"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Faq />
                        </React.Suspense>
                    }
                />
                <Route
                    path="about"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <About />
                        </React.Suspense>
                    }
                />
                <Route
                    path="mediaKit"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <MediaKit />
                        </React.Suspense>
                    }
                />
                <Route
                    path="incentives"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Incentives />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

function HomeLayout() {
    const classes = useStyles();

    return (
        <AppShell hideFooter menuItems={MenuItems}>
            <Box my={2}>
                <Box className={classes.heading}>
                    <Typography className={classes.welcome} component="h1">
                        Welcome &nbsp;
                        <span style={{ color: '#2DABFF' }}>
                            {Session?.user?.name?.split(" ")?.[0]},
                        </span>
                    </Typography>
                    <CenterFlexBox className={classes.emailContainer}>
                        <EmailOutlinedIcon /> &nbsp;
                        {Session?.user?.email}
                    </CenterFlexBox>
                </Box> <br />
                <Divider />
            </Box>
            <br />
            <Outlet />
            <br /><br />
        </AppShell>
    );
}