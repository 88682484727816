import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import AppShell from '../components/AppShell';
import { LightButton } from '../components/Button';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%",
  },
  button: {
    width: 245
  }
});

function NotFound() {
  const classes = useStyles();

  return (
    <AppShell>
      <Box className={classes.container}
      >
        <Typography
          variant='body1'
          color='white.main'
          fontWeight={600}
          mb='3em'
        >
          The page you are looking for is not found
        </Typography>
        <Box textAlign='center'>
          <Typography
            variant='h5'
            color='#D8D8D8'
            fontWeight={600}
            mb={-5}
          >
            Error
          </Typography>
          <Typography
            variant='h1'
            color='#D8D8D8'
            fontFamily='Sora'
            fontSize={300}
          >
            404
          </Typography>
        </Box>
        <a style={{ textDecoration: 'none' }} href="/">
          <LightButton
            variant='contained'
            className={classes.button}
          >
            Go Back to Homepage
          </LightButton>
        </a>
      </Box>
    </AppShell>
  );
}

export default NotFound;
