import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  image: {
    display: 'none',
    margin: '1em auto',
    width: 200,
    height: 200,
    [theme.breakpoints.down("sm")]: {
      display: 'block'
    }
  }
}));

const Container = styled("div")(
  ({
    theme,
    largewidth,
    smallwidth,
    textalign,
    margintop,
    marginbottom,
    smallmargin,
  }) => ({
    width: largewidth,
    margin: `${margintop} auto ${marginbottom} auto`,
    textAlign: textalign,
    [theme.breakpoints.down("sm")]: {
      width: smallwidth,
      margin: `${smallmargin} auto`,
      textAlign: 'center'
    },
  })
);

function InfoDiv({
  title,
  titleVariant,
  subtitle,
  subtitleVariant,
  textAlign,
  largeWidth,
  smallWidth,
  marginTop,
  marginBottom,
  smallMargin,
  imgLink = null
}) {
  const classes = useStyles();
  return (
    <Container
      largewidth={largeWidth}
      smallwidth={smallWidth}
      textalign={textAlign}
      margintop={marginTop}
      marginbottom={marginBottom}
      smallmargin={smallMargin}
    >
      <Typography variant={titleVariant} gutterBottom>
        {title}
      </Typography>
      {imgLink && <img src={imgLink} alt='shape' className={classes.image} />}
      <Typography variant={subtitleVariant} gutterBottom color='#858C90'>
        {subtitle}
      </Typography>
    </Container>
  );
}

export default InfoDiv;
