import React from "react";
import Box from "@mui/material/Box";
import CenterFlexBox from "components/CenterFlexBox";

const icons = [
  {
    label: 'whatsapp',
    url: 'https://assets.languify.in/images/whasapp.svg',
    href: 'https://api.whatsapp.com/send?&text='
  },
  {
    label: 'facebook',
    url: 'https://assets.languify.in/images/facebook.svg',
    href: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  {
    label: 'linkedin',
    url: 'https://assets.languify.in/images/linkedin.svg',
    href: 'https://www.linkedin.com/shareArticle?url='
  },
  {
    label: 'twitter',
    url: 'https://assets.languify.in/images/twitter.svg',
    href: 'https://twitter.com/intent/tweet?url='
  },
];

function ShareOptions({ referralLink }) {
  return (
    <Box display={'flex'} gap={2}>
      {
        icons.map((icon, index) => (
          <a
            key={index}
            href={
              (['facebook', 'linkedin'].includes(icon.label)) ?
                icon.href + referralLink :
                icon.href + "Give toefl mock using my referral link " + referralLink
            }
            target="_blank" rel="noreferrer"
          >
            <CenterFlexBox
              height={50} width={50} borderRadius={2}
              backgroundColor='#0F3854'
              style={{ cursor: 'pointer' }}
              key={index}
            >
              <img src={icon.url} alt={icon.label} height='60%' />
            </CenterFlexBox>
          </a>
        ))
      }
    </Box>
  );
}
export default ShareOptions;